var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: 'gdpr-block position-fixed' + (_vm.gdpr === false || _vm.gdpr === 1 ? ' d-none' : ' d-block'),
    attrs: {
      "id": "gdpr-block"
    }
  }, [_c('div', {
    staticClass: "container bg-mix-light box-shadow-1 px-4 text-justify text-md-left py-3 rounded"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "col-md-2 text-center align-self-center mt-3 mt-md-0"
  }, [_c('a', {
    staticClass: "cy-btn cy-btn--primary py-2 cursor-pointer",
    attrs: {
      "id": "gdpr-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.gdprAccept();
      }
    }
  }, [_vm._v("Accept")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-10 align-self-center cy-heading-5 letter-spacing-1 font-weight-400"
  }, [_vm._v("\n        To enhance your experience on our website, we use cookies to help us\n        understand how you interact with our website. By continuing navigating\n        through Cyware’s website and its products, you are accepting the\n        placement and use of cookies. You can also choose to disable your web\n        browser’s ability to accept cookies and how they are set. For more\n        information, please see our\n        "), _c('a', {
    staticClass: "text-decoration-none text-link-blue",
    attrs: {
      "href": "/legal/privacy-policy/",
      "target": "_blank",
      "rel": "noreferrer noopener"
    }
  }, [_vm._v("Privacy Policy")]), _vm._v(".\n      ")]);

}]

export { render, staticRenderFns }