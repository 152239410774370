<template>
  <div
    id="gdpr-block"
    :class="
      'gdpr-block position-fixed' +
      (gdpr === false || gdpr === 1 ? ' d-none' : ' d-block')
    "
  >
    <div
      class="
        container
        bg-mix-light
        box-shadow-1
        px-4
        text-justify text-md-left
        py-3
        rounded
      "
    >
      <div class="row">
        <div
          class="
            col-md-10
            align-self-center
            cy-heading-5
            letter-spacing-1
            font-weight-400
          "
        >
          To enhance your experience on our website, we use cookies to help us
          understand how you interact with our website. By continuing navigating
          through Cyware’s website and its products, you are accepting the
          placement and use of cookies. You can also choose to disable your web
          browser’s ability to accept cookies and how they are set. For more
          information, please see our
          <a
            href="/legal/privacy-policy/"
            target="_blank"
            class="text-decoration-none text-link-blue"
            rel="noreferrer noopener"
            >Privacy Policy</a
          >.
        </div>
        <div class="col-md-2 text-center align-self-center mt-3 mt-md-0">
          <a
            id="gdpr-btn"
            class="cy-btn cy-btn--primary py-2 cursor-pointer"
            @click="gdprAccept()"
            >Accept</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CyGdpr',
  data() {
    return {
      gdpr: false
    }
  },
  mounted() {
    if (localStorage.cygdpr) {
      this.setGdpr(1)
    } else {
      this.setGdpr('display')
    }
  },
  methods: {
    setGdpr(data) {
      this.gdpr = data
    },
    gdprAccept() {
      localStorage.setItem('cygdpr', 1)
      this.setGdpr(1)
    }
  }
}
</script>
